<template>
    <div>
        <vue-header title="历史报告" isReturn="true" :fixed="true" :isClose="true" :noClose="!Return" :notoken="false"/>
        <vueTime :returnFun="TimeFun" />
        <div class="cn-trend-box flex" v-if="bar.y.length>0">
            <div class="cn-trend-title" :style="{ lineHeight: `${272/bar.y.length}px`}">
                <div v-for="( item, index ) in bar.y" v-bind:key="index">{{item}}</div>
            </div>
            <div id="trend" class="cn-trend"></div>
        </div>

        <div class="prescribe-num " v-if="tongji">
            <div class="prescribe-hong flex flex-x flex-y">
                <div>
                    <div>{{tongji.user_test_count}}</div>
                    <div>总次数</div>
                </div>
            </div>
        </div>
    
        <div class="prescribe-tion" v-if="type==2 && !report_load && report_list.length>0">
            <div class="prescribe-box flex" v-for="(items,indexs) in report_list"  v-bind:key="indexs">
                <router-link :to="`/report/${items.token}`" class=" flex flex-y flex-1" >
                    <div class="flex-1">
                        <div class="flex">
                            <div class="prescribe-name prescribe-start flex flex-1 flex-y" v-if="items.flow_step!==14">
                                <div>健康状态：</div>
                                <div class="flex flex-1">
                                    <div class="sick-text">{{items.result_text}}</div>
                                </div>
                            </div>
                            <div v-else class="prescribe-name prescribe-start flex flex-1 flex-y">
                                <div>中医体质辨识</div>
                            </div>
                            <div class="flex flex-y">
                                <div class="flex-1"></div>
                                <div>
                                    <div class="prescribe-look">点击查看</div>
                                </div>
                            </div>
                        </div>
                        <div class="prescribe-look">{{items.created_at}}</div>
                    </div>
                </router-link>
                <div class="prescribe-share flex flex-x flex-y" @click="()=>shareFun(items.token)">
                    <div>
                        <div>分享</div>
                        <div>报告</div>
                    </div>
                </div>
                <div v-if="info && info.amount_id" class="prescribe-share lan-share flex flex-x flex-y" @click="()=>sendFun(items.token)">
                    <div>
                        <div>发送</div>
                        <div>报告</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert" v-if="shareBool" @click="()=>this.shareBool=false">
            <div class="alert-top">
                <img src="../../../../images/share.png" class="alert-share" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState  , mapActions , mapMutations } from 'vuex';
import vueHeader from '@/components/header';
import vueTime from '@/components/time';
import { dateZero } from "@/utils";
let timer;
export default {
  name: 'reportList',
  components:{
    vueHeader,
    vueTime
  },
  data(){
      return {
        Return:this.$route.query.return?this.$route.query.return:false,
        start_time:'',
        end_time:'',
        shareBool:false,
      }
  },
  created:function(){
      this.configData();
      this.axiosMain({})
  },
  destroyed: function () {
      this.closeFun();
      window.removeEventListener('scroll', this.onSrollFun, false);
  },
  computed:{
      ...mapState('list',['report_list','type','health_load','report_load','total','census','judge','time']),
      ...mapState('redEnvelope',['bar','tongji']),
      ...mapState('profile',['info']),
  },
  methods:{
      TimeFun(time){
            this.start_time = time.start1;
            this.end_time = time.end1;

            let phone = this.$route.query.phone;
            let sys_company_id = this.$route.query.sys_company_id;

            if(phone && sys_company_id){
                    this.axiosTel({
                        data:{
                            company_id:sys_company_id,
                            phone:phone,
                            name:'',
                            birthday:''
                        },
                        _this:this,
                        func:this.mainFun
                    })
            }else{
                this.mainFun();
            }
            let user_id = this.$route.params.id?this.$route.params.id:0;
            this.axiosTongji({
                start_time:time.start1,
                end_time:time.end1,
                user_id,
                type:2
            });
            this.axiosTrend({
                start_time:time.start1,
                end_time:time.end1,
                user_id
            });
      },
      sendFun(token){
          //发送模版消息
            this.axiosSend({
                data:{
                    token
                }
            })
      },
      shareFun(token){
            this.shareBool = true;
            let wx  = window.wx;
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({ 
                    title: `AI中医健康报告`, // 分享标题
                    link: `${document.location.protocol}//${window.location.host}/report/${token}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {} 
                })
                wx.updateTimelineShareData({ 
                    title: `AI中医健康报告`, // 分享标题
                    link: `${document.location.protocol}//${window.location.host}/report/${token}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {}
                })
            });
      },
      mainFun(){
            let token = this.$route.query.user_id;
            if(token){
                sessionStorage.setItem('token',token)
            }
            this.changeRed({key:'type',value:2,obj:{
                user_id:this.$route.params.id,
                start_time:this.start_time,
                end_time:this.end_time
            }});
            window.addEventListener('scroll', this.onSrollFun);
      },
      back(){
          window.history.back(-1);
      },
      onSrollFun(){
            let _this = this;
            clearTimeout(timer);
            timer = setTimeout(()=>{
                let height = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight;
                //获取滚动条到底部的距离
                if(height<1000){
                    _this.axiosMain({
                        user_id:_this.$route.params.id,
                        type:4
                    });
                }
            },1000)
      },
      ...mapMutations('list',['changeRed','closeFun','monthFun','weekFun','dateChange']),
      ...mapActions('list',['axiosMain','axiosCensus']),
      ...mapActions('device',['axiosTel']),
      ...mapActions('redEnvelope',['axiosTongji','axiosTrend','axiosHongbaoSend']),
      ...mapActions ("report", ["axiosSend"]),
      ...mapActions('profile',['axiosMain']),
      ...mapActions('user',['configData'])
  },
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'reportList';
</style>
